import React from "react"

import Header    from "../../components/Header/upcc"
import WallNews  from "../../components/upcc/wall-news"
import Patrimony from "../../components/upcc/patrimony"
import Footer    from "../../components/share/footer"


export default () => {

  return (
    <>
      <Header/>

      <div class="upcc-container">

        <WallNews/>

        <Patrimony/>

      </div>

      <Footer/>
    </>
  )
}
